import React from "react";
import Layout from "../components/layout";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import {
  mainRed,
  mainYellow,
  mainWhite,
  lightGrey,
  mainBlack,
  transHover,
  innerWidth,
  screen,
} from "../components/common/variables";
import { toSlug } from "../helpers";
import StyledButton from "../components/styled-button";
import IconSwirl from "../images/svg/icon-shine.svg";
import SEO from "../components/seo";
import SubBanner from "../components/banner/sub";
import ThreeColumnBanner from "../components/banner/three-col";
import ReactPlayer from "react-player";
import Cta from "../components/cta";
import BackgroundImage from "../components/background-image";
import FallbackImgSquare from "../components/fallback-images/fallback-event-img-square";
import FallbackImgLandscape from "../components/fallback-images/fallback-event-img-landscape";

const Wrapper = styled.div`
  .banner {
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
      border-top: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
      border-top: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      .txt-col {
        .capital-heading {
          margin: 12px 0 0 0;
          @media ${screen.xsmall} {
            margin: 25px 0 0 0;
          }
        }
      }
    }
  }

  .program {
    background: ${lightGrey};
    border-bottom: 22px ${mainWhite} solid;
    @media ${screen.xsmall} {
      border-bottom: 15px ${mainWhite} solid;
    }
    @media ${screen.large} {
      border-bottom: 17px ${mainWhite} solid;
    }

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 22px 50px 22px;
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        flex-direction: row;
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
          padding: 0 30px 0 0;
        }

        .capital-subheading {
          margin: 0 0 22px 0;
          text-align: center;
          padding: 0 22px;
          @media ${screen.xsmall} {
            margin: 0 0 30px 0;
            padding: 0;
          }
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
          }
          @media ${screen.large} {
            margin: 0 0 35px 0;
          }
        }

        .normal-p {
          font-size: 1rem;
          font-weight: 300;
          margin: 0 0 22px 0;
          text-align: center;
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
          }
          @media ${screen.large} {
            font-size: 1.1rem;
          }
        }

        &--right {
          padding: 0;
          @media ${screen.small} {
            margin: 0 0 0 10px;
          }

          .bordered-p {
            border-bottom: 2px ${mainRed} solid;
            margin: 0 0 25px 0;
            padding: 0 0 25px 0;
            text-align: center;
            @media ${screen.xsmall} {
              padding: 0 0 50px 0;
              margin: 0 0 50px 0;
              text-align: left;
            }

            p {
              font-size: 1.22rem;
              font-weight: 400;
              @media ${screen.xsmall} {
                font-size: 1.4rem;
              }
              @media ${screen.large} {
                font-size: 1.5rem;
              }
            }

            strong {
              font-weight: 600;
            }

            &:last-child {
              border-bottom: none;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .workshops {
    @media ${screen.xsmall} {
      margin: 0;
    }

    .featured-txt {
      color: ${mainWhite};

      .inner-txt {
        .yellow-heading {
          font-weight: 600;
          line-height: 1.45;
          color: ${mainYellow};
          text-align: center;
          @media ${screen.xsmall} {
            font-size: 1.5rem;
          }
          @media ${screen.small} {
            text-align: left;
          }
          @media ${screen.large} {
            font-size: 1.9rem;
          }
        }

        .normal-p {
          text-align: center;
          margin: 20px 0 0 0;
          @media ${screen.small} {
            max-width: 530px;
            text-align: left;
          }

          p {
            font-size: 1rem;
            font-weight: 300;
            margin: 0 0 20px 0;
            @media ${screen.large} {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }

  .featured-vid {
    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }
    }

    .flex-row {
      @media ${screen.small} {
        display: flex;
      }

      .col {
        @media ${screen.small} {
          width: 50%;
        }

        &--left {
          padding: 0 30px 0 0;

          .capital-heading {
            text-align: center;
            @media ${screen.small} {
              text-align: left;
            }
          }

          .dl-link {
            margin: 20px auto 40px auto;
            display: table;
            @media ${screen.xsmall} {
              margin: 25px auto 50px auto;
            }
            @media ${screen.small} {
              margin: 30px 0 0 0;
            }
          }
        }

        &--right {
        }
      }
    }

    .player-wrapper {
      max-width: 900px;
      margin: 0 auto;

      .player-subwrapper {
        width: 100%;
        position: relative;
        padding-top: 56.25%;

        .react-player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  .past-events {
    background: ${mainWhite};
    color: ${mainBlack};
    text-align: center;
    position: relative;

    .inner-wrapper {
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 41px 0 50px 0;
      @media ${screen.xsmall} {
        padding: 100px 42px;
      }
      @media ${screen.small} {
        padding: 100px 30px;
      }
      @media ${screen.large} {
        padding: 110px 0;
      }
    }

    .event-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      margin: 30px -9px 0 -9px;
      @media ${screen.xsmall} {
        margin: 46px -9px 0 -9px;
      }

      &__each {
        cursor: pointer;
        width: calc(100% - 18px);
        position: relative;
        margin: 0 9px 60px 9px;
        @media ${screen.xsmall} {
          width: calc(33.33% - 18px);
          margin: 0 9px 18px 9px;
        }
        @media ${screen.small} {
          width: calc(25% - 18px);
        }

        .img-wrapper {
          .desktop {
            display: none;
            @media ${screen.xsmall} {
              display: block;
            }
          }

          .mobile {
            @media ${screen.xsmall} {
              display: none;
            }
          }
        }

        .overlay {
          display: none;
          @media ${screen.medium} {
            background: rgba(255, 255, 255, 0.8);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: ${mainBlack};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            pointer-events: none;
            transition: ${transHover};
          }

          .heading {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 1px;
            margin: 0 0 22px 0;
            text-transform: uppercase;
            padding: 0 20px;
          }

          .btn {
            cursor: pointer;
            color: ${mainBlack};
            background: none;
            border: 2px ${mainBlack} solid;
            border-radius: 3px;
            font-size: 1.02rem;
            font-weight: 500;
            letter-spacing: 1px;
            padding: 10px 30px;
            text-transform: uppercase;
          }
        }

        .title-on-mobile {
          font-size: 1.02rem;
          font-weight: 500;
          letter-spacing: 2px;
          text-transform: uppercase;
          margin: 17px 0 0 0;
          padding: 0 11px;
          @media ${screen.medium} {
            display: none;
          }
        }

        .mobile-btn {
          margin: 17px 0 0 0;
          @media ${screen.medium} {
            display: none;
          }
        }

        @media ${screen.withCursor} {
          &:hover .overlay {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    .icon-decor {
      position: absolute;
      display: block;
      top: -72px;
      left: -35px;
      @media ${screen.small} {
        top: -115px;
      }

      svg {
        width: 85px;
        @media ${screen.small} {
          width: 133px;
        }
      }
    }
  }
`;

const SchoolProgramPage = ({ data }) => {
  const {
    title_tag,
    meta_description,
    banner_heading,
    banner_left_image,
    banner_right_image,
    workshop_image,
    workshop_description,
    workshop_heading,
    program_heading,
    top_description,
    bottom_description,
  } = data.content.data;

  // events with school program tag
  const pastEvents = data.pastEvents.edges;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <ThreeColumnBanner className="banner">
          <div className="inner-wrapper">
            <div className="img-col img-col--left">
              <GatsbyImage
                image={banner_left_image.thumbnails.thumbnail.gatsbyImageData}
                alt={banner_left_image.alt || "Featured banner"}
              />
            </div>
            <div className="txt-col">
              <p className="red-heading">Sydney street choir</p>
              <h1 className="capital-heading">{banner_heading.text}</h1>
            </div>
            <div className="img-col">
              <GatsbyImage
                image={banner_right_image.thumbnails.thumbnail.gatsbyImageData}
                alt={banner_right_image.alt || "Featured banner"}
              />
            </div>

            <BackgroundImage
              className="img-col img-col--mobile"
              bgUrl={banner_right_image.url}
            />
          </div>
        </ThreeColumnBanner>

        <div className="program">
          <div className="inner-wrapper">
            <div className="col">
              <h3 className="capital-subheading">{program_heading.text}</h3>
            </div>

            <div className="col col--right">
              <div>
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{ __html: top_description.html }}
                />
                <div
                  className="bordered-p"
                  dangerouslySetInnerHTML={{
                    __html: bottom_description.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <SubBanner className="workshops" bgUrl={workshop_image.url}>
          <div className="inner-wrapper">
            <div className="featured-txt">
              <div className="inner-txt">
                <h4 className="yellow-heading">{workshop_heading.text}</h4>
                <div
                  className="normal-p"
                  dangerouslySetInnerHTML={{
                    __html: workshop_description.html,
                  }}
                />
              </div>
            </div>
          </div>
        </SubBanner>

        <section className="featured-vid">
          <div className="inner-wrapper">
            <div className="flex-row">
              <div className="col col--left">
                <h3 className="capital-heading">This Little Light of Mine</h3>

                <StyledButton
                  className="dl-link"
                  href="https://sydneystreetchoir.org.au/pdf/This-Little_Light-of-Mine-Education-Pack.pdf"
                >
                  DOWNLOAD EDUCATIONAL RESOURCE
                </StyledButton>
              </div>
              <div className="col col--right">
                <div className="player-wrapper">
                  <div className="player-subwrapper">
                    <ReactPlayer
                      url={
                        "https://www.youtube.com/watch?v=h_eP6YuMdLg&ab_channel=SydneyStreetChoir"
                      }
                      controls={true}
                      className="react-player"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="past-events">
          <div className="inner-wrapper">
            <h2 className="capital-heading">Past school programs</h2>

            <ul className="event-list">
              {pastEvents.map((item) => (
                <li
                  className="event-list__each"
                  key={item.node.id}
                  onClick={() =>
                    navigate(
                      `/events/${toSlug(item.node.data.event_title.text)}`
                    )
                  }
                >
                  <div className="img-wrapper">
                    {item.node.data.gallery_list.length > 0 ? (
                      <GatsbyImage
                        className="desktop"
                        image={
                          item.node.data.gallery_list[0].image.thumbnails.square
                            .gatsbyImageData
                        }
                        alt={
                          item.node.data.gallery_list[0].image.alt ||
                          "Thumbnail"
                        }
                      />
                    ) : (
                      <FallbackImgSquare className="desktop" />
                    )}
                    {item.node.data.gallery_list.length > 0 ? (
                      <GatsbyImage
                        className="mobile"
                        image={
                          item.node.data.gallery_list[0].image.thumbnails
                            .thumbnail.gatsbyImageData
                        }
                        alt={
                          item.node.data.gallery_list[0].image.alt ||
                          "Thumbnail"
                        }
                      />
                    ) : (
                      <FallbackImgLandscape className="mobile" />
                    )}
                  </div>
                  <div className="overlay">
                    <h3 className="heading">
                      {item.node.data.event_title.text}
                    </h3>
                    <button className="btn">SEE MORE</button>
                  </div>
                  <h4 className="title-on-mobile">
                    {item.node.data.event_title.text}
                  </h4>
                  <StyledButton
                    className="mobile-btn"
                    onClick={() =>
                      navigate(
                        `/events/${toSlug(item.node.data.event_title.text)}`
                      )
                    }
                  >
                    SEE EVENT
                  </StyledButton>
                </li>
              ))}
            </ul>
          </div>
          <span className="icon-decor">
            <IconSwirl />
          </span>
        </section>

        <Cta
          heading={"Get in touch"}
          description={
            "To find out more about the Discover Your Own Voice workshops or to get involved, get in touch today."
          }
        />
      </Wrapper>
    </Layout>
  );
};

export default SchoolProgramPage;

export const dataQuery = graphql`
  {
    content: prismicSchoolProgramPage {
      data {
        title_tag
        meta_description
        banner_heading {
          text
        }
        banner_left_image {
          alt
          thumbnails {
            thumbnail {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        banner_right_image {
          alt
          url
          thumbnails {
            thumbnail {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        program_heading {
          text
        }
        top_description {
          html
        }
        bottom_description {
          html
        }
        workshop_heading {
          text
        }
        workshop_description {
          html
        }
        workshop_image {
          alt
          url
        }
      }
    }

    pastEvents: allPrismicEvent(
      filter: { data: { category: { slug: { eq: "school-program" } } } }
    ) {
      edges {
        node {
          id
          data {
            event_title {
              text
            }
            gallery_list {
              image {
                alt
                url
                thumbnails {
                  square {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                  thumbnail {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
